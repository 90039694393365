@media (max-width: $screen-md-max) {
  header.page-header .nav-global ul li a {
    min-width: 0; 
  }
}
@media (max-width: $screen-sm-max) {
  .form-horizontal {
    .control-label {
      text-align: left;
    }
  } 
}