$white: #fff;

$primary-color: #f7c633;
$secondary-color: #363636;

//green colors
$brand-greendark: #e2b233;
$brand-green: #e2b233;
$brand-greenlight: #ffe053;
$brand-red: #c14052;

//tabs color
$brand-n0: #f7c633;
$brand-n1: #E2B223;
$brand-n2: #f7c633;
$brand-n3: #f7c633;

//header
$header-text: #e4e4e4;
$header-nav-tab-text: #fff;
$header-nav-tab-active: #fff;
$header-nav-tab-selected: lighten($brand-n0, 30%);
$header-nav-tab-text-active: #363636;

//button
$button-background: #f7c633;
$button-text: #363636;
$button-border: #353535;
$button-hover-background: #e2b223;
$button-active-background: #e2b233;
$button-calendar-border: #e2b233;
$button-calendar-td-background: #f5f5f5;

//dropdown
$dropdown-border: #353535;
$dropdown-background: #fff;

//paragraph
$p-small-color: #9e9e9e;

//table
$table-border: #9e9e9e;

//box
$box-background: #f8f8f8;
$box-border: #e7e7e7;

//input
$input-background: #fff;
$input-border: #9d9d9d;

//alerts
$alert-danger-background: #fbcfcf;
$alert-danger-color: #ee3f3f;
$alert-danger-border: #f8b2b2;
