table.table-data {
  width: 100%;
  border: 1px solid $table-border;
  border-collapse: collapse;
  margin-bottom: 20px;

  td, th {
    border: 1px solid $table-border;
  }

  thead {
    background: $secondary-color;
    color: $white;
    @extend %dm;
    text-transform: uppercase;
    @include fsize(14px, 20px);
    tr {
      th {
        padding: 10px 10px;
        border-top-color: $secondary-color;
        &:first-child {
          border-left-color: $secondary-color;
        }
        &:last-child {
          border-right-color: $secondary-color;
        }
        &.sortable {
          cursor: pointer;
          background: no-repeat right center;

          &.asc {
            background-image: url("../images/icons/sort-asc.png");
          }

          &.desc {
            background-image: url("../images/icons/sort-desc.png");
          }
        }
      }
    }
  }

  tbody {
    tr {
      td {
        @extend %v;
        @include fsize(11px, 16px);
        padding: 10px 10px;
      }

      &.faded {
        opacity: 0.7;
      }

      &.row-splitter > td {
        border-top-width: 2px;
        border-top-color: black;
        border-top-style: dashed;
      }
    }
  }

  .section {
    @include fsize(14px, 16px);
    @extend %dm;
  }

  &.table-admin {
    tbody {
      tr {
        td {
          @extend %dm;
          @include fsize(14px, 18px);

          &.nowrap {
            white-space: nowrap;
          }
        }
        &.not-confirmed {
          td {
            color: #999999;
            .glyphicon-info-sign {
              font-size: 20px;
              cursor: pointer;
              color: #666666;
            }
          }
        }
      }
    }
  }
}

.table-striped > tbody > tr:nth-child(odd) > td, .table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #fff;
}

.table-striped > tbody > tr:nth-child(2n+2) > td, .table-striped > tbody > tr:nth-child(2n+2) > th {
  background-color: #ededed;
}

.table-sorted {
  border-collapse: collapse;
  td, th {
    border: 1px solid $table-border;
  }

  thead {
    background: $secondary-color;
    color: $white;
    @extend %db;
    font-weight: normal;
    text-transform: uppercase;
    @include fsize(14px, 18px);
    border: 0;
    tr {
      th {
        padding: 14px 15px;
        border-bottom: 0;
        border-top-color: $secondary-color !important;
        &:first-child {
          border-left-color: $secondary-color;
        }
        &:last-child {
          border-right-color: $secondary-color;
        }
      }
    }
  }
  tbody {
    @extend %dm;
    tr {
      td {
        padding: 14px 15px;
      }
    }

  }
}

.table-campaigns {
  input[type=number], input.text-number {
      width: 50px;
      &[disabled] {
          background-color: $box-border;
      }
  }

  input.long-field {
    width: 60px;
  }

  select {
      width: 100px;
  }

  thead {
    background: $secondary-color;
    color: $white;
  }

  td {
    vertical-align: middle !important;
    font-size: 90%;

    &.state-inactive{
      opacity: 0.6;
    }

    &.daily-budget {
      min-width: 110px;
      input {
        width:80px;
      }
    }

    &.rev-val {
       min-width: 80px;
    }
  }

  th {

    &.sortable {
      cursor: pointer;
      background: no-repeat right 70%;

      &.asc {
        background-image: url("../images/icons/sort-asc.png");
      }

      &.desc {
        background-image: url("../images/icons/sort-desc.png");
      }
    }

    &[colspan] {
      text-align: center;
      color: #e2b233;
    }
  }
}

tr.top-border td {
  border-top: 2px solid $table-border-color !important;
}

.table-overflow {
  width: 100%;
  margin-bottom: 15px;
  overflow-x: auto;
}

table.table-small {
  thead, tbody {
    tr {
      td, th {
        font-size: 9pt !important;
        padding: 2px;
      }
      td {
        font-family: $font-family-base !important;
      }
    }
  }
}

hr.small {
  margin: 10px 0;
}

td.cpm {
  color: blue;
}

td.cpa {
  color: green;
}

td.cpacpm {
  color: red;
}
