$standart-font: #ffffff;
$highlight-font: #FFC926;
$underlight-lines: #3b3b3b;
$underlight-font: #666666;
$light-underlight-font: #929292;
$reverse-underlight-font: #666666;
$reverse-standart-font: #000000;
$background: #292929;
$background-secondary: #F8F8F8;
$background-tertiary: #FFFFFF;
$black-hole-color: #222222;
$hr-reverse-color: #DDDDDD;
$site-selector-bg: #252525;
$header-informations-color: #adadad;
$border-light: #D2D2D2;
$calendar-active-bg: #ffc200;
$calendar-active-border: #e1ab00;
$calendar-hover-bg: #EFEFEF;
$border-dark: #0d0d0d;
$site-selector-bg-light: #dddddd;
$reverse-selector-font: #292929;
$reverse-table-header-font: #525252;
$alert-msg: #da5b44;
$preloader-bg: #444444;


$data-container-width: 970px;
$bg-container-width: 1200px;

$standart-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;