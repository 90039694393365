.popover {
  @include border-radius(0);
  border: 1px solid $secondary-color;
  @include box-shadow(0 2px 2px rgba(0,0,0,.48));
  padding: 25px 15px;
  max-width: none;
  .form-horizontal {
    margin-bottom: -15px;
  }

  &.bottom {
    .arrow {
      border-width: 6px;
      border-bottom-color: $secondary-color;
      top: -12px;
      &:after {
        border-width: 6px;
        top: -5px;
        margin-left: -6px;
      }
    }
  }
}

.popover-container {
  position: relative;
}

//.popover-data-content {
//  display: none;
//}

.popover-header-wrapper {
  .popover {
    &.bottom {
      .arrow {
        display: none;
        border-bottom-color: $secondary-color;
      }
    }
  }

  &.popover-page-width .popover {
      width: 100% !important;
      max-width: 100% !important;
    }

    &:not(.right) .popover {
        left: 0px !important;
    }

    &.right .popover {
        right: 0px !important;
        left: auto !important;
    }
}

.popover-header-wrapper2 {
    .popover {
        width: 400px;
    }
}

.popover-header-wrapper-small {
  .popover {
    width: 270px;
  }
}

.popover-header-wrapper-big {
  .popover {
    width: 600px;
  }
}

#registered-acc-details, #revenue-chart {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: transparent;
  overflow: auto;
  top: 0;
  left: 0;
  .registered-acc-details-modal, .revenue-chart-modal {
    z-index: 2;
    padding: 20px;
    position: relative;
    top: 50px;
    margin: auto;
    width: 850px;
    height: 430px;
    background-color: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    > form {
      display: inline-block;
    }
    .close-button {
      float: right;
      font-size: 20px;
      cursor: pointer;
    }
    .registered-acc-chart flot > div {
      margin: auto;
    }
    .bolder {
      font-weight: 700;
    }
  }
  .registered-acc-details-darkness, .revenue-chart-darkness {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: #000000;
    overflow: auto;
    top: 0;
    left: 0;
    opacity: 0.5;
    z-index: 1;
  }
}
