$icons-layout:horizontal;
// @include all-ico-sprites(true);

$icons: sprite-map("icons/*.png");
[class*="icons"] {
    display: inline-block;
    vertical-align: middle; 
}


