.tab-charts {
  padding: 0;
  margin: 0;
  display: table;
  border-collapse: collapse;
  border: 1px solid $secondary-color;
  width: 100%;
  li {
    display: table-cell;
    list-style-type: none;
    @extend %db;
    color: $p-small-color; 
    border: 1px solid $p-small-color;
    @include transition(all .1s ease-in-out);
    cursor: pointer;
    &.active, &:hover {
      background: $secondary-color;
      border-color: $secondary-color;
      span {
        color: white;
      }
      a {
        color: white;
      }
    }
    a {
      display: block;
      padding: 15px;
      @extend %db;
      color: $p-small-color;
      @include transition(none);
      &.active, &:hover {
        text-decoration: none;
      }
    }
    span {
      text-transform: uppercase;
      color: $p-small-color;
      @extend %dr;
      display: block;
    }
  }
}

#revenue-chart .flot-x-axis > .flot-tick-label.tickLabel {
  word-break: break-word;
  word-wrap: break-word;
  padding: 0 10px;
}