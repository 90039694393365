.icons-settings {
    background-image: url("/images/icons/settings.png");
    height: 15px;
    width: 15px;
}

.icons-pagination-arrow-right {
    background-image: url("/images/icons/pagination-arrow-right.png");
    height: 16px;
    width: 10px;
}
