.panel-heading {
  background-color: #353535 !important;
  color: #FFFFFF !important;
  & i {
    top: 7px;
    font-size: 10px;
  }

  & a {
    & input {
      color: #000000 !important;
    }
  }
}

.tags .panel-heading {
  background-color: #F0F0F0 !important;
  color : #353535 !important;
  & i {
    top: 7px;
    font-size: 10px;
  }

  & a {
    & input {
      color: #000000 !important;
    }
  }
}


.siteform {
  & .form-group label {
    line-height: 2;
  }
}

uib-accordion uib-accordion .panel-heading {
  background-color: #F0F0F0 !important;
  color : #353535 !important;
}

button.tag-white {
  background-color: #FFFFFF !important;
  color: #000000 !important;
  padding: 2px;
}

.panel-group .panel {
  overflow: visible !important;
  border-color: #ddd !important;
}

.panel-group:first-child {
  padding-top:20px;
}

.show-website {
  table {
    width: 100%;
  }
  textarea {
    resize: none;
    width: 100%;
    height: 60px;
  }
  pre {
    padding: 5px 10px;
    margin: 1em 0px;
  }
  strong:hover {
    cursor: pointer;
  }
}

uib-accordion textarea {
  resize: none;
  line-height: 8px;
}

uib-accordion input.ng-invalid{
    border-color:red;
}

uib-accordion span.error{
    color: red;
}

.test {
     padding-left: 0 !important;
    padding-right: 0 !important;
}

.url {
    font-family: dinpromedium, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol', Symbola, EmojiSymbols !important;
    padding-left: 20px;
    color: #9d9d9d;
    font-size:12px;
    width: 100%;
    height: 30px;
    line-height: 2.5;
    border: 1px solid #C5C5C5;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
}

.test2 {
    text-align:center;
    background-color: #F1F1F1;
    font-size:12px;
    width: 72px;
    height: 30px;
    line-height: 2.5;
    border: 1px solid #C5C5C5;
}

.amp_code_snippet {
  line-height: 1.2;
}
.tags_acordeon {
  strong:hover {
    cursor: pointer;
  }
  strong {
    color: #e2b233;
  }
}
