$icon-font-path: "/fonts/";
$width-default: auto; // boostrap-select

@import 'bootstrap-sass/assets/stylesheets/bootstrap';
@import 'bootstrap-select/sass/bootstrap-select';
@import '../lib/angular-datepicker/index.scss';
@import 'compass-mixins/lib/compass';
@import 'compass-mixins/lib/compass/utilities/_sprites.scss';
@import 'admin/mixins';
@import 'admin/colors';
@import 'admin/fonts';
@import 'admin/typography';
@import 'admin/header';
@import 'admin/sprites';
@import 'admin/tables';
@import 'admin/sections';
@import 'admin/calendar';
@import 'admin/boxes';
@import 'admin/forms';
@import 'admin/popover';
@import 'admin/responsive';
@import 'admin/alerts';
@import 'admin/charts';
@import 'admin/tags';
@import 'admin/icons';
@import 'advertiser/login';

@import 'advertiser/main';
@import 'report_requests/main';


.dropdown-menu .inner.open {
    float: left;
}
