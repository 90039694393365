.box {
  border: 1px solid $box-border;
  background-color: $box-background;
  margin-bottom: 20px;
  &.box-form {
    padding: 40px 50px;
    input {
      display: block;
      width: 100%;
      @include box-sizing(border-box);
      margin-bottom: 20px;
    }

    .button, button {
      text-align: center;
      width: 100%;
      max-width: 100px;
      margin: 0 auto 20px;
      display: block;
    }
  }
  &.padding-inside {
    padding: 18px;
  }

  p {
    @include fsize(12px, 16px);
    @extend %dm;
    &.header {
      @extend %db;
      @include fsize(14px, 18px);
    }

    &.regular {
      @include fsize(14px, 18px);
    }
  }

  &.box-equal-height {
    position: relative;
    .footer-absolute {
      position: absolute;
      bottom: 0;
      width: 100%;
      right: 0;
    }
    &.padding-inside {
      .footer-absolute {
        right: 18px;
        bottom: 10px;
      }
    }
  }
}
.radio.radio-checkbox {
  #radio-checkbox-button {
    margin-left: -20px;
    width: 14px;
    height: 14px;
    display: inline-block;
    background: no-repeat url("../images/icons/radio.png");
    margin-right: 6px;
    position: relative;
    top: 2px;
  }
  input[type=radio]:checked + label > #radio-checkbox-button {
    background: no-repeat -14px 0 url("../images/icons/radio.png");
  }
}
table {

  .bolder-total {
    font-weight: 700px;
    background-color: #dddddd;
  }
  .main-category {
  }
  .sub-category {
    & > td, & > th {
      border: none;
    }
    a {
      color: #826312;
    }
  }
}
