.alert {
  @include border-radius(0);
  padding: 5px 15px;
  @include fsize(16px, 18px);
  @extend %dr;
  &.alert-inline {
    display: inline-block;
    vertical-align: top;
  }
  &.alert-danger {
    background: $alert-danger-background;
    color: $alert-danger-color;
    border-color: $alert-danger-border;
  }
  &.customSeg {
    cursor: pointer;
  }
  &.alert-simple {
    margin: 0px;
  }
}
