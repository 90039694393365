h2 {
  @include fsize(18px, 20px);
  @extend %dm;
}


a {
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.uppercase {
  text-transform: uppercase;
  &.inline {
    margin-right: 20px;
  }
}

.inline {
  display: inline-block;
}

.header-buttons {
  margin-top: 15px;
  margin-bottom: 30px;
  .button {
    margin-right: 10px;
  }
}

.page-pagination {
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    @extend %dm;
    &.page-pagination-counter {
      margin: 0 7px;

      form {
          display: inline-block;

          input[type="number"] {
              width: 50px;
          }
      }
    }
  }
}

.plain-href {
  text-transform: uppercase;
  @include fsize(18px, 26px);
  @extend %dm;
  color: $secondary-color;
  [class*="icons"] {
    margin: 0 5px;
    position: relative;
    top: -1px;
  }
}

.text-href {
  &.dark {
    color: $secondary-color;
  }
}

.important {
  color: $brand-red;
}

.current-balance {
  margin: 5px 0 10px;
  p {
    display: inline-block;
    margin: 0;
    vertical-align: top;
    line-height: 14px;
    &.price {
      margin: 3px 10px 3px 0;
    }

    &.block {
      margin: 10px 0;
    }
  }

  .button {
    margin: 2px 10px 2px 0;
  }
}

p {
  &.large-bold {
    @extend %db;
    @include fsize(20px, 24px);
  }

  small {
    @extend %v;
    @include fsize(11px, 16px);
    color: $p-small-color;
  }

  &.block {
    display: block;
  }

  &.table-header {
    color: $secondary-color;
    @extend %dm;
    @include fsize(14px, 18px);
    margin-bottom: 5px;
  }

  &.header {
    @extend %db;
    @include fsize(14px, 18px);
  }

  &.regular {
    @extend %dr;
    @include fsize(14px, 18px);
  }

  &.light {
    @include fsize(14px, 18px);
    color: $p-small-color;
  }
}

span {
  &.regular {
    @extend %dr;
    @include fsize(14px, 18px);
  }

  &.light {
    @include fsize(14px, 18px);
    color: $p-small-color;
  }
}

a {
  @extend %dm;
  color: $brand-green;
  @include transition(all .2s ease-in-out);
  &:hover {
    color: darken($brand-green, 10%);
  }
}


.mt10 {
    margin-top: 10px;
}

.mb10 {
    margin-bottom: 10px;
}

.mt20 {
    margin-top: 20px;
}

.mb20 {
    margin-bottom: 20px;
}

.mt30 {
    margin-top: 30px;
}

.mb30 {
    margin-bottom: 30px;
}

.mrl20 {
    margin-left: 20px;
    margin-right: 20px;
}

.nowrap {
    white-space: nowrap;
}

.text-very-small {
  font-size: 70%;
}

.label-big {
  font-size: 12px;
  display: inline-block;
  width: 90px;
  padding: 5px;
}
