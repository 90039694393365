header {
  &.text {
    margin-top: 20px;
    margin-bottom: 10px;

    h1, h2, h3, h4, h5 {
      margin: 0;
    }

    .button {
      margin-bottom: 10px;
    }

    > p {
      margin: 10px 0 20px;
    }
  }

  &.page-header {
    color: $header-text;
    background: $secondary-color;
    margin: 0;
    padding: 25px 0 0;
    border: 0;
    @extend %dm;
    a {
      color: $header-text;
    }

    .page-logo {
      margin-bottom: 28px;
      display: inline-block;
    }

    &.page-header-login {
      padding: 51px 0;
      .page-logo {
        margin-bottom: 0;
      }
    }

    .debug {
      background: yellow;
    }
    .testing {
      background: blue;
    }
    .staging {
      background: #a94442;
    }
    .docker {
      background: green;
    }

    .selectpicker {
      border: 1px solid $secondary-color;
      padding-top: 7px;
      padding-bottom: 6px;
    }

    .nav-header {
      list-style-type: none;
      padding: 0;
      margin: 0 0 20px;
      font-size: 12px;
      text-align: right;
      li {
        display: inline-block;
        &:after {
          content: '\25a0';
          margin: 0 5px;
        }
        &:last-child {
          &:after {
            display: none;
          }
        }
      }
    }

    .nav-global {
      &> ul {
        padding: 0;
        margin: 0;
        height: 48px;
        &> li {
          list-style-type: none;
          display: inline-block;
          text-transform: uppercase;
          text-align: center;
          > a {
            display: inline-block;
            vertical-align: middle;
            color: $header-nav-tab-text;
            @extend %dm;
            padding: 0px 20px;
            font-size: 14px;
            line-height: 48px;
            min-width: 170px;
            @include box-sizing(border-box);
            @include transition(all .2s ease-in-out);
            &:hover, &.active {
              background: $header-nav-tab-active;
              color: $header-nav-tab-text-active;
              text-decoration: none;
            }
            &.selected {
              background: $header-nav-tab-selected;
              color: $header-nav-tab-text-active;
              text-decoration: none;
            }
            &.displayed {
              .caret {
                -ms-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
              }
            }
          }

          &.n0 {
            background: $brand-n0;
            position: relative;
          }

          &.n1 {
            background: $brand-n1;
          }

          &.n2 {
            background: $brand-n2;
          }

          &.n3 {
            background: $brand-n3;
          }

          &.menu-link-symbol {
            > a {
              font-size: 24px;
              min-width: 0px;
              padding: 0px 15px;
            }
          }
        }
      }
    }
    ul.admin-tools-menu {
      position: absolute;
      background-color: #ffffff;
      border: 1px solid #000000;
      border-top: none;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding: 10px;
      width: 246px;
      right: -1px;
      z-index: 50;
      > li {
        list-style: none;
        > a {
          color: #000000;
          padding: 0 10px;
          cursor: pointer;
          display: inline;
          text-transform: none;
          &:hover {
            text-decoration: underline;
          }
          &.active {
            background-color: #f7c633;
            border-radius: 9px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
