@import 'constants';

.loginContainer{
  bottom:0px;
  padding-left: 11px;
  width: 100%;
  h3{
    font-size: 18px;
    margin-top: -15px;
     &+span {
      font-size:12px;
    }
  }
  .form-group{
    margin-top:20px;
    margin-bottom:20px;
    & .sublabel {
     font-size: 11px;
     width: 457px;
     display: block;
   }
  }
  .registerError {
    margin-left: 10px;
    color: #FF5D5D;
    font-size: 11px;
    .errorSign{
      display: inline-block;
      background-color: #FF5D5D;
      color: #FFFFFF;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      padding-right: 1px;
      padding-top: 1px;
    }
  }

  label{
    color: #FFFFFF;
    font-weight: bold;
    display: block;
  }
  input{
    display: inline-block;
    width: 457px;
    height: 33px;
    background-color: $site-selector-bg;
    border-radius: 5px;
    boder: 1px solid #8B8B8B;
  }
  .resetPass {
    font-size: 12px;
    margin-left: 20px;
  }

  .loginFooter{
    width: 99%;
  }

  .alert.alert-danger {
    background:none;
    border: none;
  }
}


.signInContainer{
  top:198px;
  position:absolute;
  padding-left: 11px;
  width: 100%;
  h3{
    font-size: 18px;
    margin-top: -15px;
     &+span {
      font-size:12px;
      width: 457px;
      display: block;
    }
  }
  .form-group{
    margin-top:20px;
    margin-bottom:20px;
    & .sublabel {
     font-size: 11px;
     width: 457px;
     display: block;
   }

   & .seg-switcher {
     &.bootstrap-select {
       width: 457px;
       > button.selectpicker > span.caret {
         border-top-color: $standart-font;
       }
     }
     &.bootstrap-select > button, &.open > button {
       margin: 0;
       border: 1px solid #8B8B8B;
       border-radius: 5px;
       background-color: $site-selector-bg;
       width: 457px;
       height: 35px;
       color: #FFFFFF;
       font-size: 13px;
       font-weight: 400;
       font-family: Arial;
       &:hover, &:active {
         background-color: $site-selector-bg;
         color: $highlight-font;
         border: 1px solid $border-dark;
       }
       &:active, &:focus {
         color: $standart-font;
       }
     }
     &.open > button {
       border: 1px solid $border-light;
       border-bottom: none;
       background-color: $background-secondary;
       color: $background;
       border-bottom-right-radius: 0px;
       border-bottom-left-radius: 0px;
       &:hover, &:active, &:focus {
         background-color: $site-selector-bg-light;
         border: 1px solid $border-light;
         border-bottom: none;
         color: $background;
       }
     }
     &.bootstrap-select .dropdown-menu {
       &::after, &::before {
         display: none;
       }
     }
     .dropdown-menu {
       background-color: $background-secondary;
       border: 1px solid $border-light;
       border-top: none;
       width: 146px !important;
       border-radius: 3px;
       border-top-right-radius: 0px;
       border-top-left-radius: 0px;
       margin-top: 0px;
       box-shadow: none;
       a{
         display:block;
       }
       ul.inner {
        border: none;
       }
       ul.inner > li {
         a::before {
           display: none;
         }
         background-color: $background-secondary;
         .text {
           color: $background;
           font-weight: 400;
           font-family: Arial;
           font-size: 13px;
         }
         &:hover, &:active, &:focus  {
           a {
             background: $site-selector-bg-light;
           }
           .text {
             color: $background;
           }
         }
         &.selected {
           .text {
             font-weight: 700;
             color: $background;
           }
           &:hover, &:active, &:focus {
             background: $background-secondary;
             .text {
               color: $background;
             }
           }
         }
       }
     }

   }

  }

  .registerError {
    margin-left: 10px;
    color: #FF5D5D;
    font-size: 11px;
    .errorSign{
      display: inline-block;
      background-color: #FF5D5D;
      color: #FFFFFF;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      padding-right: 1px;
      padding-top: 1px;
    }
  }

  label{
    color: #FFFFFF;
    font-weight: bold;
    display: block;
  }
  input{
    display: inline-block;
    width: 457px;
    height: 33px;
    background-color: $site-selector-bg;
    border-radius: 5px;
    boder: 1px solid #8B8B8B;
  }

  .resetPass {
    font-size: 12px;
    margin-left: 20px;
  }

  .loginFooter{
    position: absolute;
    bottom: 0;
    width: 99%;
  }

  .alert.alert-danger {
    background:none;
    border: none;
  }

  .loginFooter{
    position: static;
  }

}
