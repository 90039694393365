@keyframes preloader {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

@keyframes tags-tooltip {
    0% {top: 50px;}
    50% {top: 45px;}
    100% {top: 50px;}
}

.flot-tooltip-campaigns {
  background-color: $background !important;
  border: none !important;
  color: $standart-font !important;
  font-size: 13px !important;
  font-family: $standart-font-family !important;
  span {
    font-weight: 700 !important;
  }
}

.advertiser-site {
  @import 'constants';
  &.page-header {
    background-color: $background;
    .container {
      width: $data-container-width;
    }
    .site-selection-label {
      float: right;
      font-weight: 700;
      margin-top: 14px;
      margin-right: 10px;
      color: $highlight-font;
    }
    .nav-global > ul {
      li.n3 {
        background-color: transparent;
        text-transform: none;
        color: $standart-font;
        margin-right: 35px;
        position: relative;
        top: 1px;
      }
      li a {
        padding: 0px 10px;
        min-width: 0px;
        margin-bottom: 3px;
        &.active, &:hover {
          color: $highlight-font;
          border-bottom: solid 3px $highlight-font;
          background-color: transparent;
          margin-bottom: 0;
        }
      }
    }
    .line-under-nav-buttons {
      border-top-color: $underlight-lines;
      margin: auto;
      width: 100%;
      max-width: $bg-container-width;
      min-width: $data-container-width;
    }
  }
  .icons-settings-color {
    margin-left: 10px;
    margin-top: 15px;
    float: right;
    cursor: pointer;
  }
  .alert {
    font-family: $standart-font-family;
  }
  .footer-top {
    color: $light-underlight-font;
    a {
      color: $light-underlight-font;
      font-family: $standart-font-family;
    }
  }
  .footer-bottom {
    color: $underlight-font;
    margin-top: 12px;
    margin-bottom: 50px;
  }
  .center {
    text-align: center;
  }
  .nav-header, .nav-header > li > a {
    font-family: Arial;
    font-size: 12px;
    font-family: 400;
    line-height: 36px;
    color: $header-informations-color;
  }
  .nav-header > li:after {
    content: '\2022' !important;
    margin: 0px !important;
    color: $standart-font;
    padding: 0 5px;
  }
  .site-switcher {
    &.bootstrap-select {
      float: right;
      > button.selectpicker > span.caret {
        border-top-color: $standart-font;
      }
    }
    &.bootstrap-select > button, &.open > button {
      border: 1px solid $border-dark;
      border-radius: 3px;
      background-color: $site-selector-bg;
      width: 146px;
      height: 35px;
      color: $standart-font;
      font-size: 13px;
      font-weight: 400;
      font-family: Arial;
      float: right;
      padding-left: 10px;
      position: relative;
      top: 5px;
      &:hover, &:active, &:focus {
        background-color: $site-selector-bg;
        color: $highlight-font;
        border: 1px solid $border-dark;
      }
      &:active, &:focus {
        color: $standart-font;
      }
    }
    &.open > button {
      border: 1px solid $border-light;
      border-bottom: none;
      background-color: $background-secondary;
      color: $background;
      &:hover, &:active, &:focus {
        background-color: $site-selector-bg-light;
        border: 1px solid $border-light;
        border-bottom: none;
        color: $background;
      }
    }
    &.bootstrap-select .dropdown-menu {
      &::after, &::before {
        display: none;
      }
    }
    .dropdown-menu {
      background-color: $background-secondary;
      border: 1px solid $border-light;
      border-top: none;
      min-width: 144px;
      border-radius: 3px;
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      margin-top: 0px;
      box-shadow: none;
      &.open {
      }
      ul.inner > li {
        a::before {
          display: none;
        }
        background-color: $background-secondary;
        .text {
          color: $background;
          font-weight: 400;
          font-family: Arial;
          font-size: 13px;
        }
        &:hover, &:active, &:focus  {
          a {
            background: $site-selector-bg-light;
          }
          .text {
            color: $background;
          }
        }
        &.selected {
          .text {
            font-weight: 700;
            color: $background;
          }
          &:hover, &:active, &:focus {
            background: $background-secondary;
            .text {
              color: $background;
            }
          }
        }
      }
    }
    &.last-button .dropdown-menu.open li:last-child > a {
      border: 1px solid $calendar-active-border;
      border-radius: 3px;
      background-color: $highlight-font;
      color: $background;
      font-size: 14px;
      text-align: center;
      padding-top: 1px;
      font-family: $standart-font-family;
      width: 134px;
      height: 25px;
      margin: 0 5px;
      > span {
        font-weight: 700;
      }
    }
  }
  .date-picker-button {
    border: 1px solid $border-dark;
    border-radius: 3px;
    background-color: $site-selector-bg;
    width: 219px;
    height: 35px;
    color: $standart-font;
    font-size: 13px;
    font-weight: 400;
    font-family: Arial;
    float: right;
    padding: 7px;
    margin-top: 15px;
    text-align: right;
    margin-left: 20px;
    &:hover, &:active, &:focus {
      text-decoration: none;
    };
    .caret {
      margin-right: 4px;
      margin-left: 8px;
    }
    .icons-calendar-picker {
      float: left;
      margin-left: 2px;
    }
  }
  .time-zone-info {
      float: right;
      color: white;
      position: relative;
      top: -80px;
      font-size: 12px;
      font-style: italic;
      font-weight: 700;
      left: 212px;
      &.billing {
        margin-right: 322px;
        top: -58px;
        left: 0px;
      }
  }
  .time-zone-campaigns {
    top: -105px;
  }
  .dropdown-calendar {
    padding: 10px;
    font-family: Arial;
    font-size: 12px;
    font-weight: 400;
    color: $background;
    position: absolute;
    top: 4px;
    left: -54px;
    &.billing {
      left: 124px;
    }
    .date-inputs {
      margin-top: 10px;
      > input {
        border-radius: 3px;
        border-width: 1px;
        border-color: $border-light;
        border-style: solid;
        background-color: $background-tertiary;
        width: 97px;
        height: 33px;
        font-family: Arial;
        font-size: 13px;
        font-weight: 400;
        color: $background;
        margin-left: 5px;
        margin-right: 20px;
      }
      > button {
        float: right;
        border: 1px solid $calendar-active-border;
        border-radius: 3px;
        background-color: $highlight-font;
        color: $background;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        width: 102px;
        height: 29px;
      }
    }
    .date-picker {
      padding: 0px;
      table {
        font-family: Arial;
        font-size: 12px;
        font-weight: 400;
        color: $background;
        thead {
          tr {
            background-color: $background-tertiary;
            width: 28px;
            .switch {
              border-left: none;
              border-right: none;
              font-weight: 400;
            }
            &:first-child {
              th {
                &:first-child {
                  border-right: none;
                }
                &:last-child {
                  border-left: none;
                }
              }
            }
            &:last-child {
              th {
                border: none;
                font-weight: 400;
              }
              border: 1px solid $border-light;
            }
          }
        }
        th, td {
          border: 1px solid $border-light;
        }
        td {
          width: 33px;
          span {
            border-radius: 0px;
            margin: 0px;
          }
          .before.after, .before.after.active {
            background: $calendar-active-bg !important;
            color: $background;
          }
          .disabled {
            display: none;
          }
          &:hover {
            background: $calendar-hover-bg;
          }
          .now {
            background: $calendar-hover-bg;
            color: $background;
          }
        }
      }
    }
  }
  &.analytics{
    background-color: $background;
    color: $standart-font;
    .container {
      width: $data-container-width;
      padding: 25px;
      .container-title-large {
        font-size: 36px;
        font-weight: bold;
        &.awareness {
          margin-top: 16px;
          margin-bottom: 28px;
        }
      }
      .container-title {
        font-size: 24px;
        font-weight: bold;
      }
      .contact-large {
        font-size: 18px;
        font-weight: bold;
      }
      .contact-small {
        font-size: 14px;
        color: $light-underlight-font;
        &.reverse {
          color: $reverse-underlight-font;
        }
      }
      .center {
        text-align: center;
      }
      .value-large {
        font-size: 48px;
        line-height: 1;
      }
      .value {
        font-size: 48px;
        padding: 0px;
        padding-top: 20px;
        padding-right: 97px;
        &.no-padding {
          padding-right: 0px;
        }
        &.small {
          font-size: 36px;
          &.smaller {
            font-size: 32px;
          }
        }
      }
      hr {
        border-color: $hr-reverse-color;
        margin-top: 4px;
        margin-bottom: 12px;
        &.long {
          margin-left: -140px;
          margin-right: -140px;
        }
        &.no-margin-bottom {
          margin-bottom: 0px;
        }
      }
      button {
        border: none;
        border-radius: 3px;
        background-color: $highlight-font;
        color: $background;
        font-size: 14px;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 10px;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      }
      &.secondary {
        background-color: $background-secondary;
        color: $reverse-standart-font;
        width: $bg-container-width;
        padding: 25px 140px;
        &.no-padding-bottom {
          padding-bottom: 0px;
        }
      }
      &.tertiary {
        background-color: $background-tertiary;
        color: $reverse-standart-font;
        width: $bg-container-width;
        padding: 25px 140px;
      }
      .awereness-pie-area {
        border: 1px solid $underlight-lines;
        border-radius: 10px;
        height: 200px;
        padding: 0px;
        .awereness-values-area {
          padding: 40px;
          padding-right: 20px;
          &.right {
            padding-left: 30px;
            padding-right: 5px;
          }
        }
      }
      .highlight {
        color: $highlight-font;
      }
      .underlight {
        color: $underlight-font;
      }
      .small-notes {
        font-size: 12px;
        color: $underlight-font;
        margin-top: 3px;
        line-height: 14px;
        font-weight: normal;
        &.legend-inline {
          display: inline;
          position: relative;
          top: -2px;
        }
      }
      .awareness-pie-label {
        font-size: 16px;
      }
      .black-hole {
        background-color: $black-hole-color;
        width: 250px;
        height: 250px;
        border-radius: 125px;
        padding: 13px;
        position: relative;
        top: -20px;
        left: -20px;
      }
      .ranking-o-meter {
        margin-top: 15px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .ranking-notes {
        line-height: 14px;
        margin-top: 60px;
        padding-left: 20px;
        .ranking-title {
          margin-bottom: 7px;
          font-size: 13px;
        }
        &.revenue {
          padding-left: 35px;
        }
      }
      .ranking-higher {
        position: relative;
        top: -33px;
      }
      .ranking-little-higher {
        position: relative;
        top: -5px;
      }
      .legend-circle {
        width: 12px;
        height: 12px;
        background-color: #1a1a1a;
        border-radius: 6px;
        display: inline-block;
        margin-left: 30px;
        margin-right: 10px;
        position: relative;
        top: 2px;
        &.gradient {
          background: linear-gradient(#44ba27, #ffc600, #FF0000);
        }
      }
      .awareness-rating-trend {
        position: relative;
        left: -11px;
        margin-bottom: 30px;
      }
      .trend-7-label {
        font-size: 12px;
        line-height: 12px;
        color: $underlight-font;
        margin-bottom: 5px;
        position: relative;
        left: 5px;
      }
      .smaller-margin {
        margin-bottom: -25px;
      }
      .border-left {
        border-left: solid 1px $hr-reverse-color;
        height: 244px;
      }
      .revenue-margin {
        margin-top: 12px;
      }
      .average-conversion-margin {
        margin-left: 30px;
        margin-top: 12px;
        width: 46%;
      }
      .average-conversion-ranking-closer {
        width: 206px;
      }
      .co-op {
        padding-top: 30px;
        > div {
          height: 250px;
        }
        .extended-reach {
          width: 525px;
          border-right: solid 1px $hr-reverse-color;
          float: left;
          padding-right: 30px;
          padding-left: 30px;
          .value {
            font-size: 30px;
            padding-right: 0px;
          }
          .small-notes {
            margin: 0px;
          }
        }
        .top-cross {
          width: 400px;
          float: left;
          padding-right: 0px;
          padding-left: 60px;
          table {
            margin-top: 10px;
            line-height: 30px;
            font-size: 14px;
            color: $reverse-underlight-font;
            width: 100%;
            td:last-child {
              color: $reverse-standart-font;
              font-weight: 700;
              text-align: right;
            }
          }
          hr {
            width: calc(100% - 30px);
          }
        }
      }
    }
  }
  .sorry-msg-on-chart {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    &.big {
      top: 120px;
      left: 350px;
    }
    &.medium {
      top: 30px;
      left: 27px;
      color: $reverse-underlight-font;
    }
    &.small {
      width: 175px;
      color: $reverse-underlight-font;
    }
  }
  .preloader {
    width: 1200px;
    height: 600px;
    border-bottom: 1px solid $underlight-lines;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding-top: 100px;
    .circle {
      width: 75px;
      height: 75px;
      display: block;
      border: 4px solid $black-hole-color;
      border-radius: 50%;
      background-color: $highlight-font;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;

      background-image:
          linear-gradient(140deg, transparent 50%, $highlight-font 50%),
          linear-gradient(90deg, $preloader-bg 50%, transparent 50%);

      animation: preloader 2s linear 0s infinite;

      .hole {
        position: relative;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: $background;
        top: 11px;
        left: 11px;
      }
    }
  }
  &.campaigns, &.account {
    background-color: $background;
    padding-top: 0px;
    .container.top-section {
      background-color: $background;
      width: 100%;
    }
    .row.top-section {
      width: $data-container-width;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      padding-top: 20px;
      button#export-excel-conversions:before {
        content: 'Conversions details';
          color: #FFFFFF;
          position: absolute;
          top: 59px;
          right: 185px;
          font-style: italic;
          font-size: 12px;
      }
      button#export-excel-creatives:before {
        content: 'Creatives details';
          color: #FFFFFF;
          position: absolute;
          top: 59px;
          right: 185px;
          font-style: italic;
          font-size: 12px;
      }
      &.billing-bottom {
        padding-top: 0px;
      }
      &.billing-top {
        padding-bottom: 0px;
      }
      h2 {
        font-family: $standart-font-family;
        color: $standart-font;
        font-weight: 700;
      }
      .balance {
        color: $highlight-font;
        font-size: 36px;
        font-weight: 700;
      }
      .block {
        position: relative;
        top: -5px;
        > a {
          font-size: 13px;
          font-family: Arial;
          color: $highlight-font;
          font-weight: 700;
        }
      }
      .underlight-notes {
        color: $underlight-font;
        font-size: 11px;
        line-height: 11px;
        position: relative;
        top: -12px;
        padding-bottom: 25px;
      }
      .calendar {
        float: right;
        display: inline-block;
      }
      .calendar, .site-switcher {
        margin-left: 20px;
        margin-top: 10px;
      }
      .deposit-button {
        width: 100%;
        padding-top: 77px;
        margin: 0px;
        &.billing {
          width: 146px;
          padding: 0px;
          float: right;
          margin-top: 17px;
          margin-left: 20px;
        }
        .button {
          float: right;
          border: 1px solid #e1ab00;
          border-radius: 3px;
          background-color: #FFC926;
          color: #292929;
          font-size: 14px;
          font-weight: bold;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          width: 146px;
          height: 29px;
          &.excel {
              margin-right: 19px;
              width: 219px;
          }
        }
      }
    }
    .container.bottom-section {
      width: $bg-container-width;
      background-color: $background-secondary;
      padding: 100px 125px;
      margin-bottom: 100px;
      .chart-alert {
        text-align: center;
        width: 100%;
        position: relative;
        top: -50px;
        color: $alert-msg;
        font-size: 13px;
      }
      .flot-border {
        border: 1px solid $hr-reverse-color;
        width: 970px;
        border-top: none;
        overflow: hidden;
        margin-bottom: 30px;
        background-color: #f4f4f4;
        td, th {
          padding: 2px;
        }
      }
      h2 {
        font-family: $standart-font-family;
        font-weight: 700;
        margin-bottom: 5px;
      }
      .balance {
        color: $highlight-font;
        font-size: 36px;
        font-weight: 700;
      }
      .flot-x-axis {
        top: -35px !important;
      }
      .tab-charts {
        width: $data-container-width;
        background-color: $background-tertiary;
        border: 1px solid $hr-reverse-color;
        border-radius: 2px;
        border-bottom: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        line-height: 10px;
        li {
          background-color: transparent;
          border: none;
          .cell-border {
            height: 80px;
            border: 1px solid $reverse-standart-font;
            border-radius: 2px;
            background-color: $background;
            margin-left: -1px;
            position: relative;
          }
        }
        a {
          padding: 5px 15px;
          font-size: 13px;
          font-family: $standart-font-family;
          text-transform: capitalize;
          color: $standart-font;
          font-weight: 700;
          .top-categories {
            color: $hr-reverse-color;
            font-weight: 400;
            height: 50px;
          }
        }
        .active {
          background-color: transparent;
          border: none;
          .cell-border {
            margin-left: 0px;
            background-color: $background-tertiary;
            border: none;
              a {
              color: $reverse-standart-font;
              .top-categories {
                color: $reverse-standart-font;
              }
            }
          }
        }
      }
    }
    .billing-table-header {
      color: $reverse-table-header-font;
      margin-top: 0px;
      margin-bottom: 20px !important;
    }
    .alert-danger.billing {
      font-family: Arial;
      font-size: 12px;
    }
    .table-overflow, .table-billing {
      overflow-x: auto;
      .table-campaigns {
        thead {
          background-color: transparent;
        }
        width: 100%;
        &.billing {
          width: 100%;
        }
        .details {
          position: absolute;
          text-align: left;
          padding: 10px;
          min-width: 218px;
          border-radius: 5px;
          margin-left: 69px;
          margin-top: -47px;
          background: $calendar-hover-bg;
          border: 1px solid $light-underlight-font;
          table {
            td {
              height: 22px;
              border: none;
              text-align: left;
              vertical-align: top !important;
              &:first-child {
                width: 110px;
                font-weight: 700;
              }
              &:last-child {
                font-weight: 400;
                width: initial;
              }
            }
          }
          .triangle-border {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 6px 0;
            border-color: transparent $light-underlight-font transparent transparent;
              position: absolute;
              left: -6px;
              top: 31px;
              .triangle {
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 5px 5px 5px 0;
              border-color: transparent $calendar-hover-bg transparent transparent;
                position: absolute;
                top: -5px;
                left: 1px;
              }
          }
          .close-button {
            position: absolute;
              right: 5px;
              top: 5px;
              font-size: 14px;
              cursor: pointer;
          }
        }
        th, td {
          width: 150px;
          height: 60px;
          vertical-align: middle;
          text-align: center;
          background-color: transparent;
          font-weight: 700;
          font-size: 13px;
        }
        th {
          color: $reverse-table-header-font;
          border: none;
        }
        td {
          border-top: 1px solid $hr-reverse-color;
          color: $reverse-underlight-font;
          &:first-child {
            text-align: left;
            font-weight: 400;
          }
        }
      }
    }
    .zero-state-chart {
        padding: 50px 0px;
        width: 100%;
        margin: auto;
        text-align: center;
    }
  }
  &.account {
    h2 + span {
      color: $light-underlight-font;
      font-size: 13px;
      position: relative;
      top: -10px;
    }
    div#settingsSuccess{
      color: #4cbb17;
      text-align: center;
    }
    div.container.bottom-section{
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom:100px;
      padding-left:125px;
      padding-right:125px;
      .form-group{
        margin-top:20px;
        margin-bottom:20px;
        & .sublabel {
         font-size: 11px;
         width: 457px;
         display: block;
         color:  #B4B4B4;
       }
      }
      .registerError {
        margin-left: 10px;
        color: #FF5D5D;
        font-size: 11px;
        .errorSign{
          display: inline-block;
          background-color: #FF5D5D;
          color: #FFFFFF;
          width: 22px;
          height: 22px;
          border-radius: 22px;
          text-align: center;
          font-size: 15px;
          font-weight: bold;
          padding-right: 1px;
          padding-top: 1px;
        }
      }
      & .seg-switcher {
        &.bootstrap-select {
          width: 457px;
          > button.selectpicker > span.caret {
            border-top-color: $reverse-underlight-font;
            border-top: 4px dashed;
            border-bottom: none;
          }
        }
        &.bootstrap-select > button, &.open > button {
          margin: 0;
          border: 1px solid #8B8B8B;
          border-radius: 5px;
          background-color: $calendar-hover-bg;
          width: 457px;
          height: 35px;
          font-size: 13px;
          font-weight: 400;
          font-family: Arial;
          &:hover, &:active {
            background-color: $calendar-hover-bg;
            color: #000000;
            border: 1px solid $border-dark;
          }
          &:active, &:focus {
            color: #000000;
          }
        }
        &.open > button {
          border: 1px solid $border-light;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom: none;
          background-color: $calendar-hover-bg;
          color: $background;
          &:hover, &:active, &:focus {
            background-color: $site-selector-bg-light;
            border: 1px solid $border-light;
            border-bottom: none;
            color: #000000;
          }
        }
        &.bootstrap-select .dropdown-menu {
          &::after, &::before {
            display: none;
          }
        }
        .dropdown-menu {
          background-color: $calendar-hover-bg;
          position:relative;
          border: 1px solid $border-light;
          border-top: none;
          width: 146px !important;
          border-radius: 3px;
          border-top-right-radius: 0px;
          border-top-left-radius: 0px;
          margin-top: 0px;
          box-shadow: none;
          a{
            display:block;
          }
          &.inner {
            border: none;
          }
          ul.inner > li {
            a::before {
              display: none;
            }
            background-color: $calendar-hover-bg;
            .text {
              color: $background;
              font-weight: 400;
              font-family: Arial;
              font-size: 13px;
            }
            &:hover, &:active, &:focus  {
              a {
                background: $site-selector-bg-light;
              }
              .text {
                color: #000000;
              }
            }
            &.selected {
              .text {
                font-weight: 700;
                color: #000000;
              }
              &:hover, &:active, &:focus {
                background: $calendar-hover-bg;
                .text {
                    color: #000000;
                }
              }
            }
          }
        }
}
      button {
        border: 1px solid $calendar-active-border;
        border-radius: 3px;
        background-color: $highlight-font;
        color: $background;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        width: 102px;
        height: 29px;
      }
      label{
        color: $reverse-standart-font;
        display: block;
        font-family: $standart-font-family;
        font-size: 13px;
        font-weight: 700;
      }
      input{
        font-family: $standart-font-family;
        display: inline-block;
      color: $reverse-standart-font;
        width: 457px;
        height: 33px;
        background-color: $calendar-hover-bg;
        border-radius: 3px;
        boder: 1px solid $border-light;
        &.sent.ng-invalid {
          background-color: #FFBEBE;
          border-color: #D42727;
          color: #E14242;
        }
      }
    }
  }
  &.deposit {
    .form-send select.selectpicker.ng-invalid + .bootstrap-select button {
      border: 1px solid #f8b2b2;
    }
    .card-box {
      width: 106%;
      height: 200px;
      border: 1px solid $underlight-lines;
      color: $light-underlight-font;
      padding: 20px;
      font-family: Arial;
      font-size: 11px;
      font-weight: 400;
      position: relative;
      top: -10px;
      overflow-x: hidden;
      p.header {
        font-family: Arial;
        font-size: 12px;
        font-weight: 700;
        color: $standart-font;
        margin-bottom: 0px;
        line-height: 1;
        margin-bottom: 2px
      }
      .card-data {
        width: 600px;
      }
      &.plus {
        cursor: pointer;
        text-align: center;
        padding-top: 60px;
        img {
          display: inline-block;
          margin-bottom: 15px;
        }
      }
      hr {
        position: absolute;
        width: 95px;
        bottom: 13px;
        border-top-color: $underlight-lines;
      }
      .footer-absolute {
        position: absolute;
        right: 27px;
        bottom: 11px;
      }
    }
    .box.padding-inside {
      &.left-box {
        margin-right: 30px;
        margin-left: 30px;
      }
      &.right-box {
        margin-left: 30px;
      }
      min-height: 350px;
      margin-top: 20px;
    }
    .container.bottom-section {
      margin-top: 35px;
      padding-top: 15px;
      label, p {
        font-family: $standart-font-family;
        font-size: 13px;
        &.bolder {
          font-weight: 700;
        }
      }
    }
    .container.top-section {
      padding-top: 0px;
    }
    .row.text-under-box {
      line-height: 1;
      margin-left: 30px;
      margin-right: 30px;
    }
    .popover {
      position: relative;
      top: 0px !important;
      display: inline-block !important;
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: $standart-font;
      label, p {
        font-family: $standart-font-family;
        font-size: 13px;
        color: $standart-font;
        font-weight: 700;
      }
      .popover-content {
        padding: 0;
      }
      .form-group {
        margin-bottom: 0;
      }
      .form-horizontal .form-group {
        margin: 0;
        margin-right: -10px;
      }
      input {
        margin-top: 5px;
        margin-bottom: 10px;
        border-radius: 3px;
        background-color: $background;
        color: $standart-font;
        height: 35px;
        font-family: $standart-font-family;
        font-size: 13px;
      }
      .date-selector {
        margin: 0 !important;
        > button {
          width: 100px;
          min-width: 0;
          margin: 0;
          border-color: $light-underlight-font;
        }
        .dropdown-menu {
          width: 100px;
          min-width: 0;
        }
        .dropdown-menu.open {
          overflow-x: hidden !important;
        }
        &.year {
          .dropdown-menu.open {
            left: -6px;
          }
        }
        &.month {
          > button {
            margin-right: -5px;
          }
          .dropdown-menu.open {
            left: -2px;
          }
        }
      }
      .column-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 15px;
      }
      .card-buttons {
        width: 95px;
        min-width: 0;
        margin-top: 20px;
      }
    }
    .submit-button {
      border: 1px solid $calendar-active-border;
      border-radius: 3px;
      background-color: $highlight-font;
      color: $background;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      width: 102px;
      height: 29px;
      margin-top: 10px;
      &.go-back-button {
        margin-top: 0px;
        width: 120px;
        height: 29px;
        padding-top: 3px;
        padding-left: 10px;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .site-switcher.bootstrap-select {
      float: none;
      &.right-select {
        margin-top: 10px;
        margin-bottom: 25px;
      }
    }
    .site-switcher.light-select.bootstrap-select {
      button {
        border-color: $border-light;
        background-color: $calendar-hover-bg;
        color: $reverse-selector-font;
        span.caret {
          border-top-color: $reverse-selector-font;
        }
      }
      &.dropup button span.caret {
        border-bottom-color: $reverse-selector-font;
      }
      .dropdown-menu.open {
        overflow-x: hidden !important;
      }
    }
    input[type=radio] {
      display: none;
    }
    .radio {
      margin-bottom: 15px;
      label {
        margin-left: 10px
      }
      label:before {
          content: "";
          display: inline-block;

          width: 20px;
          height: 20px;
          border: 1px solid $border-light;
          border-radius: 10px;

          position: absolute;
          left: 0;
          bottom: 1px;
          background-color: $background-tertiary;
      }
      input[type=radio]:checked + label:before {
          content: "\2022";
          color: $highlight-font;
          font-size: 30px;
          text-align: center;
          line-height: 20px;
      }
    }
    .inline {
      display: inline-block;
    }
    .icons-back2 {
      margin-right: 10px;
    }
  }
  &.tags {
    background-color: $background;
    .test {
      padding-left: 5px !important;
    }
    .test > .test2 {
      border: none;
      background-color: transparent;
      text-align: left;
      color: $light-underlight-font;
      padding-left: 10px
    }
    span, label, div, button {
      font-family: $standart-font-family;
    }
    button {
      border: 1px solid $calendar-active-border;
      border-radius: 3px;
      background-color: $highlight-font;
      color: $background;
      font-size: 14px;
      font-weight: bold;
      font-family: $standart-font-family;
      width: 80px;
      height: 29px;
      margin-left: 10px;
      margin-top: 2px;
      &.zeroclipboard-is-active {
          box-shadow: rgba(0, 0, 0, 0.498039) 0px 1px 1px 0px inset;
      }
    }
    .url {
      height: 35px;
      border-radius: 3px;
      border-color: $border-light;
      color: $border-light;
      font-family: $standart-font-family !important;
    }
    label {
      font-weight: 700;
      font-size: 12px;
      margin-left: -4px;
      color: $reverse-underlight-font;
      position: relative;
      top: -10px;
    }
    .site-name {
      color: $reverse-standart-font;
      font-size: 13px;
      font-weight: 700;
      .partner-info {
        text-transform: none;
        font-weight: 400;
        .bolder {
          font-weight: 700;
        }
      }
    }
    .partner-connect {
      margin: -5px 0px 10px 5px;
        display: inline-block;
        .partners-list {
          display: block;
          > a {
            font-family: $standart-font-family;
          }
        }
    }
    .partner-icon {
      height: 21px;
      width: 80px;
      display: inline-block;
      position: relative;
      background: no-repeat;
      background-size: 100%;
      &.icon-shopify {
        background-image: url("../images/partners/shopify.png");
        top: 5px;
        &.small {
          position: absolute;
          top: -4px;
          width: 70px;
        }
      }
      &.icon-magento {
        background-image: url("../images/partners/magento.png");
        top: 4px;
        margin-left: 1px;
        &.small {
          position: absolute;
          top: -4px;
          width: 70px;
        }
      }
      &.icon-prestashop {
        background-image: url("../images/partners/prestashop.png");
        top: 10px;
        &.small {
          position: absolute;
          top: 3px;
          width: 70px;
        }
      }
    }
    .panel-title {
      font-size: 13px;
      color: $reverse-underlight-font;
      font-weight: 700;
    }
    .panel-group {
      padding-top: 0px;
      margin-bottom: 0px;
    }
    .container.top-section {
      background-color: $background;
      width: 100%;
    }
    .row.top-section {
      width: $data-container-width;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      padding-top: 0px;
      h2 {
        font-family: $standart-font-family;
        color: $standart-font;
        font-weight: 700;
      }
      .underlight-notes {
        color: $light-underlight-font;
        font-size: 13px;
        line-height: 13px;
        position: relative;
        top: -12px;
        padding-bottom: 0px;
      }
    }
    .container.bottom-section {
      width: $bg-container-width;
      background-color: $background-secondary;
      padding: 50px 125px;
      margin-bottom: 100px;
      margin-top: 25px;
    }
    .container.tags {
      width: 100%;
    }
    .tags-tip {
      display: inline-block;
      margin-bottom: 40px;
      margin-left: 15px;
      margin-right: 15px;
      > a {
        font-family: $standart-font-family;
      }
    }
  }
  &.sites {
    .inputError {
        margin-left: 10px;
        color: #FF5D5D;
        font-size: 11px;
      .errorSign {
        display: inline-block;
        background-color: #FF5D5D;
        color: #FFFFFF;
        width: 22px;
        height: 22px;
        border-radius: 22px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        padding-right: 1px;
        padding-top: 1px;
      }
    }
    .unbold {
      font-weight: 400;
    }
  }
  .tags-tooltip {
    left: -75px;
    position: absolute;
    line-height: 1.5 !important;
    width:200px;
    color: $reverse-standart-font;
    font-family: $standart-font-family;
    padding: 10px;
    background: $highlight-font;
    border: 2px solid $underlight-lines;
    line-height: 30px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0px 0px 10px $highlight-font;
    z-index: 10;
    animation: tags-tooltip 0.7s ease 0s infinite;
    > i {
      position: absolute;
      top: 3px;
      right: 4px;
      cursor: pointer;
    }
    > span {
      font-size: 10px;
      display: block;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .tags-tooltip:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -12px;
    width: 0; height: 0;
    border-bottom: 12px solid $underlight-lines;
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
  }
  .tags-tooltip:after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0; height: 0;
    border-bottom: 8px solid $highlight-font;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
  .darkness {
    position: absolute;
    background: black;
    width: 100%;
    height: 2240px;
    opacity: 0.5;
    z-index: 8;
    top: 132px;
  }
  &.landing-page {
    .site-switcher.bootstrap-select {
      float: none;
      display: block;
      width: 500px;
      button, button:focus {
        margin-bottom: 3px;
        width: 100%;
        float: none;
        border: 1px solid $border-light;
        background-color: $calendar-hover-bg;
        color: $reverse-standart-font;
        margin-top: 0;
        &:hover {
          border-color: $border-dark;
        }
        span.caret {
          border-top-color: $reverse-standart-font;
        }
      }
      &.open button {
          border: 1px solid $border-dark;
          border-bottom: none;
          background-color: $background-secondary;
          color: $reverse-selector-font;
      }
      .dropdown-menu {
        width: 100%;
        border: 1px solid $border-dark;
        border-top: none;
        > ul {
          border: none;
        }
      }
    }
    h3 {
      margin-bottom: 5px;
    }
    h4 {
      margin-top: 40px;
    }
    label {
      color: $standart-font;
    }
    span.sublabel {
      display: block;
      color: $light-underlight-font;
      font-size: 12px;
    }
    input {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        display: block;
        color: $reverse-standart-font;
        width: 500px;
        height: 33px;
        background-color: $calendar-hover-bg;
        border-radius: 3px;
        border: 1px solid $border-light;
        font-weight: 700;
        font-size: 14px;
        padding-left: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }
    button {
      margin-right: 10px;
    }
    span.subtitle {
      color: $light-underlight-font;
    }
    .registerError {
      color: #AA0000;
      font-size: 12px;
      display: block;
      .errorSign {
        display: inline-block;
        background-color: #AA0000;
        color: #FFFFFF;
        width: 22px;
        height: 22px;
        border-radius: 22px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        padding-right: 1px;
        padding-top: 1px;
      }
    }
  }
}
#shopify-connected-success-msg-darkness {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.7;
  z-index: 10;
}
#shopify-connected-success-msg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 100;
  > div {
    width: 400px;
    background-color: $background-secondary;
    border-radius: 5px;
    border: solid 1px #000000;
    margin: auto;
    margin-top: 200px;
    span {
      display: block;
      margin: 20px;
    }
    button {
      border: 1px solid #e1ab00;
      border-radius: 3px;
      background-color: #FFC926;
      color: #292929;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
      width: 169px;
      height: 29px;
      margin: 20px;
      margin-top: 0;
      margin-right: 0;
    }
  }
}

.display-inline-block {
  display: inline-block !important;
}

.advertiser-site.campaigns .container.bottom-section .tab-charts a .top-categories, .advertiser-site.account .container.bottom-section .tab-charts a .no-capitalize {
  text-transform: none;
}


.pagination-container {
  background: white;
  position: relative;
}
.pagination-container .page-pagination {
  margin-top: 8px;
}
.advertiser-site.account div.container.bottom-section.wide {
  padding: 25px 25px;
}

header.page-header .nav-global > ul > li > a.adhesiveDanger {
  color: #ff0000;
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

@keyframes spinner-border {
  100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

div.vueframe.page-content {
  div.container-fluid {
    div.row {
      position: relative;

      iframe {
        border: 0;
        position: absolute;
        top: 0px;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        margin: -15px;
        padding: 0;
      }
    }

  }
}


