.border_top {
    border-top: 1px solid black;
    margin-bottom: -6px;
    padding-bottom: 6px;
    padding-top: 6px;
}

.border_bottom {
    border-bottom: 1px solid black;
    padding-bottom: 6px;
}

.border_left {
    border-left: 1px solid black;
    margin-left: 20px;
}
.border_right {
    border-right: 1px solid black;
}

.bottom_margin {
    margin-bottom: 30px;
}

.title {
     float: none;
     margin: 0 auto;
     text-align: center;
     padding: 15px;
}
