$fontsPath: "/fonts/";

@font-face {
    font-family: 'dinpromedium';
    src: url('#{$fontsPath}dinpro-medium-webfont.eot');
    src: url('#{$fontsPath}dinpro-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fontsPath}dinpro-medium-webfont.woff') format('woff'),
         url('#{$fontsPath}dinpro-medium-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dinprobold';
    src: url('#{$fontsPath}dinpro-bold-webfont.eot');
    src: url('#{$fontsPath}dinpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fontsPath}dinpro-bold-webfont.woff') format('woff'),
         url('#{$fontsPath}dinpro-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dinproregular';
    src: url('#{$fontsPath}dinpro-regular-webfont.eot');
    src: url('#{$fontsPath}dinpro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fontsPath}dinpro-regular-webfont.woff') format('woff'),
         url('#{$fontsPath}dinpro-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


* {
    -webkit-font-smoothing: antialiased;
}

%dm {
    font-family: 'dinpromedium', sans-serif;
}

%db {
    font-family: 'dinprobold', sans-serif;
    font-weight: normal;
}

%dr {
    font-family: 'dinproregular', sans-serif;
}

%v {
    font-family: Verdana, sans-serif;
}
