/*!
 * Stylesheet for the Date Range Picker, for use with Bootstrap 3.x
 *
 * Copyright 2013 Dan Grossman ( http://www.dangrossman.info )
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Built for http://www.improvely.com
 */

 .daterangepicker.dropdown-menu {
  max-width: none;
  z-index: 3000;
}

.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .calendar {
  float: left;
  margin: 4px;
}

.daterangepicker.opensright .ranges, .daterangepicker.opensright .calendar {
  float: right;
  margin: 4px;
}

.daterangepicker .ranges {
  width: 160px;
  text-align: left;
}

.daterangepicker .ranges .range_inputs>div {
  float: left;
}

.daterangepicker .ranges .range_inputs>div:nth-child(2) {
  padding-left: 5px;
}

.daterangepicker .calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker.show-calendar .calendar {
    display: block;
}

.daterangepicker .calendar.single .calendar-date {
  border: none;
}

.daterangepicker .ranges label {
  color: #333;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  margin-bottom: 2px;
  text-shadow: #fff 1px 1px 0px;
  text-transform: uppercase;
  width: 74px;
}

.daterangepicker .ranges input {
  font-size: 11px;
}

.daterangepicker .ranges .input-mini {
  border: 1px solid #9d9d9d;
  background-color: #eee;
  @include border-radius(0);
  color: #555;
  display: block;
  font-size: 10px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: 0 0 10px 0;
  padding: 0 6px;
  width: 75px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.daterangepicker .ranges li {
  font-size: 12px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #08c;
  padding: 3px 12px;
  margin-bottom: 8px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.daterangepicker .ranges li.active, .daterangepicker .ranges li:hover {
  background: #08c;
  border: 1px solid #08c;
  color: #fff;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 8px auto 0 auto;
  line-height: 30px;
}

.daterangepicker {
  position: absolute;
  border: 1px solid $dropdown-border;
  background-color: $dropdown-background;
  @include box-shadow(0 2px 2px rgba(0,0,0,.25));
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 1px;
  @include border-radius(0px);
  .calendar-date {
    padding: 4px;
    background: #fff;
    border: 0;
  }

  .calendar {
    td {
      padding: 3px 0;
      @extend %dr;
      @include border-radius(0);
      white-space: nowrap;
      text-align: center;
      font-size: 12px;
      min-width: 25px;
      background: $button-calendar-td-background; 
    }
    th {
      padding: 0;
      @extend %db;
      @include border-radius(0);
      white-space: nowrap;
      text-align: center;
      font-size: 12px;
      min-width: 25px;
      font-weight: normal;
    }
  }

  td.active, td.active:hover, td.available:hover{
    background: $primary-color !important;
    background-color: $primary-color;
    border-color: $primary-color;
    color: #fff;
    @include border-radius(0);
  } 

  .applyBtn {
    @extend .button;
    @include border-radius(0);
    min-width: 0 !important;
  }

  .cancelBtn {
    @extend .button;
    @extend .button.default;
    @include border-radius(0); 
    min-width: 0 !important;
  }
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $dropdown-background;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid $dropdown-border;
  border-left: 6px solid transparent;
  content: '';
}


.daterangepicker.opensright:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensright:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker table {
  width: 100%;
  margin: 0;
  border-collapse: separate;
  border-spacing: 4px;
}

.daterangepicker td, .daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  cursor: pointer;
  white-space: nowrap;
}

.daterangepicker td.off {
  color: #999;
}

.daterangepicker td.disabled {
  color: #999;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background: #eee;
}

.daterangepicker td.in-range {
  background: $button-calendar-td-background; 
  @include border-radius(0);
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 12px;
  color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}

.daterangepicker_start_input {
  float: left;
}

.daterangepicker_end_input {
  float: left; 
  padding-left: 11px
}

.daterangepicker th.month {
  width: auto;
}


//classic datepicker
.datepicker {
  top: 0;
  left: 0;
  padding: 4px;
  margin-top: 10px;
  z-index: 1020;
  @include border-radius(0);
  border-color: $secondary-color;
  @include box-shadow(0 2px 2px rgba(0,0,0,.25));
  &:before {
    content: '';
    display: inline-block;
    border-left:   6px solid transparent;
    border-right:  6px solid transparent;
    border-bottom: 6px solid #ccc;
    border-bottom-color: $secondary-color;
    position: absolute;
    top: -6px;
    left: 7px;
  }
  &:after {
    content: '';
    display: inline-block;
    border-left:   6px solid transparent;
    border-right:  6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    top: -5px;
    left: 7px;
  }
  >div {
    display: none;
  }
  table{
    width: 100%;
    margin: 0;
    border-collapse: separate;
    border-spacing: 4px;
  }
  td,
  th{
    text-align: center;
    width: 24px;
    height: 20px;
  }
  td {
    background: whitesmoke;
    @extend %dr;
    font-size: 12px;

    &.day:hover {
      cursor: pointer;
      background: $primary-color;
      color: white;
    }
    &.day.disabled {
    }
    &.old,
    &.new {
    }
    &.active,
    &.active:hover {
      color: #fff;
      background: $primary-color;
    }
    span {
      display: block;
      width: 47px;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 2px;
      cursor: pointer;
      &:hover {
      }
      &.active {
        color: #fff;
        text-shadow: 0 -1px 0 rgba(0,0,0,.25);
      }
      &.old {
      }
    }
  }
  
  th {
    font-size: 12px;
    @extend %db;
    font-weight: normal;
    &.switch {
      width: 145px;
    }
    &.next,
    &.prev {
      &:hover {
        background: #eee;
      }
    }
  }
  
  thead tr:first-child th {
    cursor: pointer;
    &:hover{
    }
  }
  /*.dow {
    border-top: 1px solid #ddd !important;
  }*/
}
.input-append,
.input-prepend {
  &.date {
    .add-on i {
      display: block;
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}
